<template>
  <div class="craft">
    <div class="top common">
      <img src="../assets/img/工艺.jpg" alt="" />
      <img
        src="../assets/img/arrow.gif"
        class="arrow"
        @click="gotoself('.content')"
        alt=""
      />
      <div class="topleft">
        <Nav />
      </div>
      <!-- <div class="toptitle">
        <div class="topcn">原料与工艺的完美结合</div>
        <div class="topen">Perfect fusion of raw materials and technology</div>
      </div> -->
    </div>
    <div class="black"></div>
    <div class="common content">
      <div class="contain">
        <div class="containitem">
          <img
            src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/工艺页面图/LX1A9708.jpg"
            alt=""
          />
          <p>采摘</p>
        </div>
        <div class="containitem">
          <img
            src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/工艺页面图/LX1A0251.png"
            alt=""
          />
          <p>晒青</p>
        </div>
        <div class="containitem">
          <img
            src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/工艺页面图/手工茶.jpg"
            alt=""
          />
          <p>揉捻</p>
        </div>
        <div class="containitem">
          <img
            src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/工艺页面图/手工.jpg"
          />
          <p>炒制</p>
        </div>
      </div>
    </div>
    <div class="videoContent">
      <div class="videobg">
        <video
          style="width:100%"
          src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/video/%E7%94%9F%E6%9D%A5%E4%B8%8D%E5%87%A1.mp4"
          controls
          muted
          :autoplay="dialogVisible"
          poster="../assets/img/videocover.jpg"
        ></video>
        <!-- <div
          class="iconfont iconbofang play"
          @click="dialogVisible = true"
          v-if="!dialogVisible"
        ></div> -->
      </div>
    </div>
    <!-- <el-dialog title="" :visible.sync="dialogVisible" @closed="videopause">
      <div style="text-align: center;">
        <video
          style="width:100%"
          src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/video/%E7%94%9F%E6%9D%A5%E4%B8%8D%E5%87%A1.mp4"
          controls
          :autoplay="dialogVisible"
        ></video>
      </div>
    </el-dialog> -->
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav";

export default {
  components: { Footer, Nav },
  data() {
    return {
      current: 0,
      dialogVisible: false
    };
  },
  methods: {
    tabTap(index) {
      this.current = index;
    },
    videopause() {
      const video = document.querySelector("video");
      video.pause();
    },
    gotoself(key) {
      this.$el.querySelector(key).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start" // 上边框与视窗顶部平齐
      });
    }
  }
};
</script>

<style lang="less" scoped>
.common {
  margin: 0 auto;
}
.top {
  height: 1080px;
  position: relative;
  // margin-bottom: 38px;
  img {
    width: 100%;
    height: 100%;
  }
  .arrow {
    width: 50px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  .topleft {
    position: absolute;
    height: 100%;
    width: 415px;
    background: rgba(0, 0, 0, 0.1) no-repeat center;
    left: 0;
    top: 0;
    // background-image: url("https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/logo.png");
    // background-size: 135px 190px;
  }
  .toptitle {
    position: absolute;
    top: 460px;
    width: 70%;
    height: 310px;
    color: #ffffff;
    left: 530px;
    .topcn {
      font-size: 75px;
      text-align: center;
      font-family: "source serif Bold";
    }
    .topen {
      font-size: 42px;
      // margin-left: 415px;
      text-align: center;
      font-family: "source serif light";
    }
  }
}
.content {
  background: #000;
  height: 1080px;
  // margin-bottom: 38px;
  position: relative;
  .contain {
    margin-left: 115px;
    margin-right: 115px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    .containitem {
      width: 410px;
      height: 100%;
      background: #1a1a1a;
      color: #fff;
      img {
        width: 100%;
        height: 785px;
        filter: brightness(70%);
        transition: 1.5s;
      }
      img:hover {
        filter: brightness(120%);
      }
      p {
        font-size: 42px;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 50px;
      }
    }
  }
}
.videoContent {
  width: 100%;
  height: 1080px;
  overflow: hidden;
  margin-bottom: -2px;
  position: relative;
  background: #000;
  .videobg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1500px;
    height: 700px;
    video {
      width: 100%;
      height: 100%;
    }
    .play {
      color: #fff;
      position: absolute;
      font-size: 94px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .play:hover {
      cursor: pointer;
    }
  }
}
.black {
  height: 80px;
  background: #000;
}
</style>

<template>
  <div id="nav" @mouseover="meunFlag = true" @mouseleave="meunFlag = false">
    <i class="el-icon-menu meun"></i>
    <transition name="slide-fade"
      ><div class="logo" v-if="!meunFlag"></div
    ></transition>
    <transition name="slide-fade">
      <div class="navList" v-if="meunFlag">
        <div
          class="navItem"
          :class="isSelect === item.title && 'selectItem'"
          @click="navTap(index)"
          v-for="(item, index) in nav"
          :key="item.id"
        >
          {{ item.title }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [
        { title: "首页", id: 1, url: "/" },
        { title: "走进生来", id: 2, url: "/story" },
        { title: "产品介绍", id: 3, url: "/product" },
        { title: "制茶工艺", id: 4, url: "/craft" },
      ],
      isSelect: "首页",
      meunFlag: false,
    };
  },
  mounted() {
    this.isSelect = this.$route.name;
  },
  methods: {
    navTap(index) {
      this.$router.push(this.nav[index].url);
    },
    tapMeun() {
      this.meunFlag = !this.meunFlag;
    },
  },
};
</script>

<style scoped lang="less">
#nav {
  // width: 1920px;
  // height: 109px;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  z-index: 9999;
  .meun {
    position: absolute;
    color: #fff;
    font-size: 48px;
    top: 70px;
    left: 70px;
    transition: 1s;
  }
  .meun:hover {
    cursor: pointer;
    // transform: rotate(90deg);
  }
  .navList {
    position: absolute;
    left: 70px;
    top: 120px;
    display: block;
    width: 65%;
    text-align: center;
    transition: 1s;
    .navItem {
      color: #fff;
      font-weight: normal;
      line-height: 100px;
      font-size: 36px;
      transition: 0.5s;
    }
    .navItem:hover {
      cursor: pointer;
      color: #edb55b;
      font-size: 38px;
    }
    // .selectItem {
    //   color: #edb55b;
    //   border-bottom: 0;
    // }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active 用于 2.1.8 以下版本 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
